export const orders = [
  {
    vb: '1957922 Ontario Ltd.',
    link: 'https://obd.hcraontario.ca/profile/B45892',
    order: 'Notice of Proposal to Refuse to Renew',
    date: 'June 11, 2020'
  },
  {
    vb: 'Albion Building Consultant Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    order: 'Notice of Proposal to Refuse to Renew',
    date: 'March 15, 2022'
  },
  {
    vb: 'Albion Building Consultant Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    order: 'Freeze Order',
    date: 'February 26, 2024'
  },
  {
    vb: 'Ideal Developments Inc.',
    link: 'https://obd.hcraontario.ca/profile/B40597',
    order: 'Notice of Proposal to Apply Conditions',
    date: 'September 9, 2021'
  },
  {
    vb: 'Ideal (BC) Developments Inc.',
    link: 'https://obd.hcraontario.ca/unlicensed/B48282',
    order: 'Notice of Proposal to Refuse to Grant',
    date: 'September 9, 2021'
  },
  {
    vb: 'Ideal (MM) Developments Inc.',
    link: 'https://obd.hcraontario.ca/profile/B44902',
    order: 'Notice of Proposal to Refuse to Renew',
    date: 'September 9, 2021'
  },
  {
    vb: 'Ideal (WC) Developments Inc.',
    link: 'https://obd.hcraontario.ca/profile/B45414',
    order: 'Notice of Proposal to Refuse to Renew',
    date: 'September 9, 2021'
  },

  {
    vb: 'Luxstone Homes Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47446',
    order: 'Notice of Proposal to Make a Compliance Order',
    note: 'Licensee has since complied',
    date: 'December 23, 2021'
  },

  {
    vb: 'Yarco Developments Inc.',
    link: 'https://obd.hcraontario.ca/profile/B46743',
    order: 'Notice of Proposal to Refuse to Renew',
    date: 'January 28, 2022'
  },

  {
    vb: 'Townsgate Homes Inc.',
    link: 'https://obd.hcraontario.ca/profile/B43218',
    order: 'Notice of Proposal to Make a Compliance Order',
    note: 'Note: Townsgate Homes Inc. has since come into compliance.',
    date: 'March 23, 2022'
  },

  {
    vb: 'Adi Developments (Masonry The West) Inc.',
    link: 'https://obd.hcraontario.ca/profile/B45614',
    order: 'Notice of Proposal to Revoke Licence',
    date: 'August 25, 2022'
  },
  {
    vb: 'Adi Developments (Masonry) Inc.',
    link: 'https://obd.hcraontario.ca/profile/B44938',
    order: 'Notice of Proposal to Revoke Licence',
    date: 'August 25, 2022'
  },
  {
    vb: 'Adi Developments (Parkview) Inc.',
    link: 'https://obd.hcraontario.ca/profile/B46333',
    order: 'Notice of Proposal to Revoke Licence',
    date: 'August 25, 2022'
  },
  {
    vb: 'Adi Developments (Portland) Inc.',
    link: 'https://obd.hcraontario.ca/profile/B61303',
    order: 'Notice of Proposal to Revoke Licence',
    date: 'August 25, 2022'
  },
  {
    vb: 'Adi Construction Management Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47966',
    order: 'Notice of Proposal to Revoke Licence',
    date: 'August 25, 2022'
  },
  {
    vb: 'Thomas Alton Midrise Towns Inc.',
    link: 'https://obd.hcraontario.ca/profile/B61269',
    order: 'Notice of Proposal to Revoke Licence',
    date: 'August 25, 2022'
  },
  {
    vb: '4880 Valera Road Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47295',
    order: 'Notice of Proposal to Apply Conditions',
    date: 'August 25, 2022'
  },
  {
    vb: 'Adi Morgan Developments (Lakeshore) Inc.',
    link: 'https://obd.hcraontario.ca/profile/B44865',
    order: 'Notice of Proposal to Apply Conditions',
    date: 'August 25, 2022'
  },
  {
    vb: 'Adi Morgan Developments (Thomas Alton) Inc.',
    link: 'https://obd.hcraontario.ca/profile/B60173',
    order: 'Notice of Proposal to Apply Conditions',
    date: 'August 25, 2022'
  },
  {
    vb: 'Marcel Dewit',
    link: 'https://obd.hcraontario.ca/profile/B35744',
    order: 'Notice of Proposal to Revoke Licence',
    date: 'September 26, 2022'
  },
  {
    vb: 'Patrick Violette ',
    link: 'https://obd.hcraontario.ca/profile/B44380',
    order: 'Freeze, Compliance and Suspension Orders ',
    date: 'February 16, 2023'
  },
  {
    vb: 'Viloham Trades Inc.',
    link: 'https://obd.hcraontario.ca/profile/B48960',
    order: 'Freeze, Compliance and Suspension Orders ',
    date: 'February 16, 2023'
  },
  {
    vb: 'Adi Morgan Developments (Lakeshore) Inc.',
    link: 'https://obd.hcraontario.ca/profile/B44865',
    order: 'Administrative Penalty',
    date: 'March 1, 2023'
  },
  {
    vb: 'Anix Developments Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47290',
    order: 'Notice of Proposal to Make a Compliance Order',
    date: 'March 8, 2023'
  },
  {
    vb: 'Riverview Homes (Niagara) Ltd.',
    link: 'https://obd.hcraontario.ca/profile/B12800',
    order: 'Compliance Order',
    date: 'April 3, 2023'
  },
  {
    vb: 'Pinetree Developments Inc.',
    link: 'https://obd.hcraontario.ca/profile/B46762',
    order: 'Immediate Compliance Order',
    date: 'March 14, 2023'
  },
  {
    vb: 'Pinetree Developments Inc.',
    link: 'https://obd.hcraontario.ca/profile/B46762',
    order: 'Immediate Compliance Order',
    date: 'March 14, 2023'
  },

  {
    vb: '2560262 Ontario Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47130',
    order: 'Compliance Order',
    date: 'April 12, 2023',
    note: 'Licensee has since complied'
  },
  {
    vb: 'Pinetree Developments Inc.',
    link: 'https://obd.hcraontario.ca/profile/B46762',
    order: 'Notice of Proposal to Make a Compliance Order',
    date: 'April 20, 2023'
  },
  {
    vb: 'Pinetree Developments Inc.',
    link: 'https://obd.hcraontario.ca/profile/B46762',
    order: 'Notice of Proposal to Revoke a Licence',
    date: 'May 9, 2023'
  },
  {
    vb: 'Stateview Homes (Minu Towns) Inc.',
    link: 'https://obd.hcraontario.ca/profile/B60578',
    order: 'Notice of Proposal to Suspend a Licence',
    date: 'June 19, 2023'
  },
  {
    vb: 'Stateview Homes (Nao Towns) Inc.',
    link: 'https://obd.hcraontario.ca/profile/B48820',
    order: 'Notice of Proposal to Suspend a Licence',
    date: 'June 19, 2023'
  },
  {
    vb: 'Stateview Homes (High Crown Estates) Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47480',
    order: 'Notice of Proposal to Suspend a Licence',
    date: 'June 19, 2023'
  },
  {
    vb: 'Stateview Homes (Bea Towns) Inc.',
    link: 'https://obd.hcraontario.ca/profile/B60186',
    order: 'Notice of Proposal to Suspend a Licence',
    date: 'June 19, 2023'
  },
  {
    vb: 'Stateview Homes (Elm&Co) Inc.',
    link: 'https://obd.hcraontario.ca/profile/B61469',
    order: 'Notice of Proposal to Suspend a Licence',
    date: 'June 19, 2023'
  },
  {
    vb: 'Stateview Homes (Ashburn Heights) Inc.',
    link: 'https://obd.hcraontario.ca/profile/B61632',
    order: 'Notice of Proposal to Suspend a Licence',
    date: 'June 19, 2023'
  },
  {
    vb: "Stateview Homes (Queen's Court) Inc.",
    link: 'https://obd.hcraontario.ca/profile/B61656',
    order: 'Notice of Proposal to Suspend a Licence',
    date: 'June 19, 2023'
  },
  {
    vb: 'Stateview Homes (Baldwin Heights) Inc.',
    link: 'https://obd.hcraontario.ca/profile/B61468',
    order: 'Notice of Proposal to Suspend a Licence',
    date: 'June 19, 2023'
  },
  {
    vb: 'Stateview Homes (Elia Collection) Inc.',
    link: 'https://obd.hcraontario.ca/profile/B61334',
    order: 'Notice of Proposal to Suspend a Licence',
    date: 'June 19, 2023'
  },
  {
    vb: 'Stateview Construction Ltd.',
    link: 'https://obd.hcraontario.ca/profile/B41428',
    order: 'Notice of Proposal to Suspend a Licence',
    date: 'June 19, 2023'
  },
  {
    vb: 'Stateview Homes (Hampton Heights) Inc.',
    link: 'https://obd.hcraontario.ca/profile/B60363',
    order: 'Notice of Proposal to Suspend a Licence',
    date: 'June 19, 2023'
  },
  {
    vb: 'Stateview Homes (Main & Co.) Inc.',
    link: 'https://obd.hcraontario.ca/profile/B48110',
    order: 'Notice of Proposal to Suspend a Licence',
    date: 'June 19, 2023'
  },
  {
    vb: 'Stateview Homes (S Collection) Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47823',
    order: 'Notice of Proposal to Suspend a Licence',
    date: 'June 19, 2023'
  },
  {
    vb: 'Stateview Homes (Edge Towns) Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47192',
    order: 'Notice of Proposal to Suspend a Licence',
    date: 'June 19, 2023'
  },
  {
    vb: 'Mansoura Development Inc.',
    link: 'https://obd.hcraontario.ca/profile/B37545',
    order: 'Notice of Proposal to Refuse to Renew',
    date: 'July 26, 2023'
  },
  {
    vb: '2363823 Ontario Inc.',
    link: 'https://obd.hcraontario.ca/profile/B43313',
    order: 'Notice of Proposal to Suspend a Licence',
    date: 'December 5, 2023'
  },
  {
    vb: 'Dynasty Home Builders Inc.',
    link: 'https://obd.hcraontario.ca/profile/B62022',
    order: 'Notice of Proposal to Refuse a Licence',
    date: 'December 11, 2023'
  },
  {
    vb: 'Hira Custom Homes Inc.',
    link: 'https://obd.hcraontario.ca/profile/B30875',
    order: 'Notice of Proposal to Refuse a Licence',
    date: 'February 12, 2024'
  },
  {
    vb: 'Zanib Butt',
    link: 'https://obd.hcraontario.ca/profile/B63019',
    order: 'Administrative Penalty',
    date: 'March 13, 2024'
  },
  {
    vb: 'Waqas Ali',
    link: 'https://obd.hcraontario.ca/profile/B63018',
    order: 'Administrative Penalty',
    date: 'March 13, 2024'
  },
  {
    vb: 'Dream Maker Developments Inc.',
    link: 'https://obd.hcraontario.ca/profile/B43317',
    order: 'Notice of Proposal to Make a Compliance Order',
    date: 'April 11, 2024',
    note: 'Licensee has since complied'
  },
  {
    vb: '9315055 Canada Corp.',
    link: 'https://obd.hcraontario.ca/profile/B44861',
    order: 'Notice of Proposal to Make a Compliance Order',
    date: 'April 11, 2024',
    note: 'Licensee has since complied'
  },
  {
    vb: 'Modern Day Builders Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47075',
    order: 'Compliance Order',
    date: 'April 29, 2024'
  },
  {
    vb: '2169289 Ontario Inc.',
    link: 'https://obd.hcraontario.ca/profile/B36401',
    order: 'Compliance Order',
    date: 'April 29, 2024'
  },
  {
    vb: 'Lynphyl Homes Limited',
    link: 'https://obd.hcraontario.ca/profile/B60522',
    order: 'Compliance Order',
    date: 'March 21, 2024'
  },
  {
    vb: 'GC King Bond GP Inc.',
    link: 'https://obd.hcraontario.ca/profile/B48213',
    order: 'Administrative Penalty',
    date: 'June 4, 2024'
  },
  {
    vb: 'Safe Harbour Homes Inc. ',
    link: 'https://obd.hcraontario.ca/profile/B43256',
    order: 'Notice of Proposal to Revoke Licence',
    date: 'May 30, 2024'
  },
  {
    vb: 'Lynphyl Homes Limited',
    link: 'https://obd.hcraontario.ca/profile/B60522',
    order: 'Freeze Order',
    date: 'June 20, 2024'
  },
  {
    vb: 'Albion Building Consultant Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    order: 'Freeze Order',
    date: 'June 27, 2024'
  },
  {
    vb: 'Harjot Khehra',
    link: 'https://obd.hcraontario.ca/profile/B63436',
    order: 'Administrative Penalty',
    date: 'July 11, 2024'
  },
  {
    vb: 'Samalie Nsubuga',
    link: 'https://obd.hcraontario.ca/profile/B62335',
    order: 'Administrative Penalty',
    date: 'July 11, 2024'
  },
  {
    vb: 'Joy Nneji',
    link: 'https://obd.hcraontario.ca/profile/B62334',
    order: 'Administrative Penalty',
    date: 'July 11, 2024'
  },
  {
    vb: 'Jasjit Sandhu',
    link: 'https://obd.hcraontario.ca/profile/B63437',
    order: 'Administrative Penalty',
    date: 'July 11, 2024'
  },
  {
    vb: '2363823 Ontario Inc.',
    link: 'https://obd.hcraontario.ca/profile/B43313',
    order: 'Administrative Penalty',
    date: 'July 31, 2024'
  },
  {
    vb: '2363823 Ontario Inc.',
    link: 'https://obd.hcraontario.ca/profile/B43313',
    order: 'Notice of Proposal to Revoke Licence',
    date: 'July 31, 2024'
  },
  {
    vb: 'Robert Toon',
    link: 'https://obd.hcraontario.ca/profile/B42866',
    order: 'Compliance Order',
    date: 'August 27, 2024'
  },
  {
    vb: 'Roca Developments Inc.',
    link: 'https://obd.hcraontario.ca/profile/B37007',
    order: 'Compliance Order',
    date: 'August 8, 2024'
  },
  {
    vb: '2145499 Ontario Inc.',
    link: 'https://obd.hcraontario.ca/profile/B48115',
    order: 'Compliance Order',
    date: 'August 27, 2024',
    note: 'Licensee has since complied'
  },
  {
    vb: 'Barakaa Developer Inc.',
    link: 'https://obd.hcraontario.ca/profile/B62296',
    order: 'Compliance Order',
    date: 'August 27, 2024',
    note: 'Licensee has since complied'
  },
  {
    vb: 'Lerrato Inc.',
    link: 'https://obd.hcraontario.ca/profile/B61168',
    order: 'Compliance Order',
    date: 'August 27, 2024',
    note: 'Licensee has since complied'
  },
  {
    vb: 'Osmi Homes Inc.',
    link: 'https://obd.hcraontario.ca/profile/B43054',
    order: 'Compliance Order',
    date: 'August 27, 2024',
    note: 'Licensee has since complied'
  },
  {
    vb: 'Albion Building Consultant Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    order: 'Notice of Application for a Restraining Order',
    date: 'August 9, 2024'
  }
]
